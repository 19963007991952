.card {
    width: 205px;
    text-align: center;
    padding: 0;

    .cardImgTop {
        width: 176px;
        margin: 5px auto 0;
        border-radius: 0;
    }

    .cardBody {
        padding: 5px;
        text-align: center;
        line-height: 1.2;
        font-size: 14px;
        white-space: pre-wrap;
    }
}

.videoCompetitions {
    background-color: #FAF0E6;
}

.girls {
    background-color: #AFD8F1;
}