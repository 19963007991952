.navigation {
    background-color: black;
    color: white;

    .togglerIcon {
        color: white;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    img {
        width: 100px;
    }

    ul {
        padding: 5px 0;

        a {
            color: white;
            padding-top: 0;
            padding-bottom: 0;

            &:hover {
                color: orange;
            }
        }

        li {
            padding-right: 25px;
        }

        li:not(:last-of-type) {
            border-right: 1px solid #13729A;
        }

        li:not(:first-of-type) {
            padding-left: 20px;
        }
    }

    .dropdownMenuSuper {
        background-color: grey;

        a {
            background-color: grey;
            &:hover {
                background-color: #555555;
            }
        }
    }
}

@media (max-width: 991px) {
    .navigation {
        ul {
            li:not(:first-of-type) {
                padding-left: 0;
            }
        }

        .dropdownMenuSuper {
            max-width: 50%;
        }
    };
}
