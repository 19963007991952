

.header {
    h3:first-of-type {
        color: var(--color-accent);
    }
}

.compArticlesVideoGirl {
    a {
        color: #007bff;
    }

    h3:first-of-type {
        color: var(--color-accent);
    }
}

.navLinkVideos {
    color: blue;
}

.videosMainImg {
    max-width: 100%;
    margin-bottom: 10px;
    text-align: center;
}

.videoGirl {


    button {
        margin-bottom: 5px;
    }

    hr {
        margin-top: 16px;
    }
}

.textAlignCenter {
    text-align: center;
}

// for reactBootstrap table
.customTablePadding > :not(caption) > * > * {
    padding: 0;
}