.logo {
  background: linear-gradient(to right, #1B8BBA, #023C56);

  .row {
    padding: 30px 20px;
  }

  h1 {
    font-size: 60px;
    color: white;
    margin: 0;
    line-height: 0.85;
    font-weight: bold;
  }

  h2 {
    font-size: 25px;
    color: orange;
    margin-left: 2px;
  }
}