.Home {
  .rowMy {
    margin-bottom: 15px;
  }

  img {
    float: left;
    margin-right: 10px;
  }

  h3 {
    a {
      color: black;

      &:hover {
        text-decoration: none;
        color: #329CCB;
      }
    }
  }

  p {
    margin-bottom: 10px;

    a:hover {
      color: black;
    }
  }

  button {
    color: white;
    background-color: #C0C0C0;
    border: none;
    height: 28px;

    &:hover {
      background-color: #1B8BBA;
    }
  }
}
