.customToggle {
    border-right: 1px solid #13729A;
    padding-left: 25px;
    padding-right: 25px;
}

@media (max-width: 991px) {
    .customToggle {
        padding-left: 0;
    }
}
